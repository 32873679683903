<template>
  <div>
    <a-page-header
      class="page-header"
      :ghost="false"
      style="margin-bottom: 16px"
    >
      <template #title>
        <a-space size="middle">
          <a-icon type="book"></a-icon>
          <span>知识库</span>
        </a-space>
      </template>
      <span>知识库包含用户操作、故障排查、告警处理等文档。 </span>
    </a-page-header>

    <a-card :bordered="false">
      <document-table
        :refresh="refresh"
        :tools-list="['reload', 'search', 'leftTool']"
        :table-operation-width="170"
      ></document-table>
    </a-card>
  </div>
</template>

<script>
import DocumentTable from '@/components/table/DocumentTable/index.vue'

export default {
  name: 'Document',
  components: {
    DocumentTable
  },
  data () {
    return {
      refresh: false
    }
  }
}
</script>
