<template>
  <div>
    <list-layout
      :can-operate="canOperate"
      :column-default-selected="columnDefaultSelected"
      :columns-all="columnsAll"
      :default-selected="tableDefaultSelectData"
      :get-table-data-func="getTableDataFunc"
      :in-modal="inModal"
      :refresh="updateTableFlag"
      :route-params="$route.params"
      :table-operation-width="tableOperationWidth"
      :tools-list="toolsList"
      @tableSelectData="getSelectData"
    >
      <template #leftTool>
        <create-button
          v-permission="{
            action: 'base.document.create',
            effect: 'disabled'
          }"
          @click="$refs.inputDrawer.show()"
        ></create-button>
      </template>
      <!-- 表格自定义列 -->
      <template #name="{ slotProps }">
        <a-tooltip placement="topLeft" :title="slotProps.text">
          <a @click="$refs.detailDrawer.show(slotProps.record.id)" v-if="link">
            {{ slotProps.text }}
          </a>
          <span v-else>{{ slotProps.text }}</span>
        </a-tooltip>
      </template>
      <template #createdBy="{ slotProps }">
        <a-tooltip placement="topLeft" :title="slotProps.text.nickname">
          {{ slotProps.text.nickname }}
        </a-tooltip>
      </template>
      <template #updatedBy="{ slotProps }">
        <a-tooltip placement="topLeft" :title="slotProps.text.nickname">
          {{ slotProps.text.nickname }}
        </a-tooltip>
      </template>
      <template #operation="{ slotProps }">
        <edit-button
          v-permission="{
            action: 'base.document.update',
            effect: 'disabled'
          }"
          @click="$refs.inputDrawer.show(slotProps.record.id)"
        ></edit-button>
        <delete-button
          v-permission="{
            action: 'base.document.delete',
            effect: 'disabled'
          }"
          @confirm="confirm(slotProps.record.id)"
        ></delete-button>
      </template>
    </list-layout>

    <document-drawer ref="detailDrawer"></document-drawer>
    <input-drawer ref="inputDrawer" @ok="confirm"></input-drawer>
  </div>
</template>

<script>
import {
  deleteDocument,
  getDocumentList
} from '@/api/document'
import ListLayout from '@/components/ListLayout'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import CreateButton from '@/components/button/CreateButton'
import DocumentDrawer from '@/components/drawer/DocumentDrawer'

export default {
  name: 'DocumentTable',
  components: {
    DeleteButton,
    DocumentDrawer,
    EditButton,
    CreateButton,
    InputDrawer: () => import('./modules/InputDrawer.vue'),
    ListLayout
  },
  props: {
    defaultSelected: {
      type: Array
    },
    toolsList: {
      type: Array,
      default: () => []
    },
    tableOperationWidth: {
      type: Number,
      default: 240
    },
    link: {
      type: Boolean,
      default: true
    },
    refresh: {
      type: Boolean
    },
    canOperate: {
      type: Boolean,
      default: true
    },
    inModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      getTableDataFunc: getDocumentList,
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'description',
          title: '描述',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'description'
          }
        },
        {
          dataIndex: 'created_by',
          title: '创建人',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'createdBy'
          }
        },
        {
          dataIndex: 'updated_by',
          title: '更新人',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'updatedBy'
          }
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        }
      ],
      columnDefaultSelected: [
        'name',
        'description',
        'updated_by',
        'updated_at'
      ],
      updateTableFlag: this.refresh,
      tableSelectData: [],
      tableDefaultSelectData: []
    }
  },
  mounted () {
    if (this.defaultSelected) this.tableDefaultSelectData = this.defaultSelected
  },
  methods: {
    getSelectData (data) {
      this.tableSelectData = data
      this.$emit('selectedData', data)
    },
    async confirm (id = '') {
      if (id) {
        await deleteDocument(id).then(res => {
          this.$message.success(res.message)
        })
        this.tableSelectData.splice(this.tableSelectData.findIndex(item => item.key === id), 1)
        this.tableDefaultSelectData = this.tableSelectData
        this.$emit('selectedData', this.tableSelectData)
      }
      this.updateTableFlag = !this.updateTableFlag
    }
  },
  watch: {
    refresh (v) {
      this.updateTableFlag = v
    }
  }
}
</script>
