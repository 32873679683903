var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('list-layout',{attrs:{"can-operate":_vm.canOperate,"column-default-selected":_vm.columnDefaultSelected,"columns-all":_vm.columnsAll,"default-selected":_vm.tableDefaultSelectData,"get-table-data-func":_vm.getTableDataFunc,"in-modal":_vm.inModal,"refresh":_vm.updateTableFlag,"route-params":_vm.$route.params,"table-operation-width":_vm.tableOperationWidth,"tools-list":_vm.toolsList},on:{"tableSelectData":_vm.getSelectData},scopedSlots:_vm._u([{key:"leftTool",fn:function(){return [_c('create-button',{directives:[{name:"permission",rawName:"v-permission",value:({
          action: 'base.document.create',
          effect: 'disabled'
        }),expression:"{\n          action: 'base.document.create',\n          effect: 'disabled'\n        }"}],on:{"click":function($event){return _vm.$refs.inputDrawer.show()}}})]},proxy:true},{key:"name",fn:function({ slotProps }){return [_c('a-tooltip',{attrs:{"placement":"topLeft","title":slotProps.text}},[(_vm.link)?_c('a',{on:{"click":function($event){return _vm.$refs.detailDrawer.show(slotProps.record.id)}}},[_vm._v(" "+_vm._s(slotProps.text)+" ")]):_c('span',[_vm._v(_vm._s(slotProps.text))])])]}},{key:"createdBy",fn:function({ slotProps }){return [_c('a-tooltip',{attrs:{"placement":"topLeft","title":slotProps.text.nickname}},[_vm._v(" "+_vm._s(slotProps.text.nickname)+" ")])]}},{key:"updatedBy",fn:function({ slotProps }){return [_c('a-tooltip',{attrs:{"placement":"topLeft","title":slotProps.text.nickname}},[_vm._v(" "+_vm._s(slotProps.text.nickname)+" ")])]}},{key:"operation",fn:function({ slotProps }){return [_c('edit-button',{directives:[{name:"permission",rawName:"v-permission",value:({
          action: 'base.document.update',
          effect: 'disabled'
        }),expression:"{\n          action: 'base.document.update',\n          effect: 'disabled'\n        }"}],on:{"click":function($event){return _vm.$refs.inputDrawer.show(slotProps.record.id)}}}),_c('delete-button',{directives:[{name:"permission",rawName:"v-permission",value:({
          action: 'base.document.delete',
          effect: 'disabled'
        }),expression:"{\n          action: 'base.document.delete',\n          effect: 'disabled'\n        }"}],on:{"confirm":function($event){return _vm.confirm(slotProps.record.id)}}})]}}])}),_c('document-drawer',{ref:"detailDrawer"}),_c('input-drawer',{ref:"inputDrawer",on:{"ok":_vm.confirm}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }