<template>
  <a-drawer
    class="detail-drawer"
    :destroy-on-close="true"
    :title="detail.name"
    :visible="visible"
    :width="800"
    @close="visible = false"
  >
    <a-descriptions :column="2">
      <a-descriptions-item label="描述" :span="2">
        {{ detail.description }}
      </a-descriptions-item>
      <a-descriptions-item label="创建人">
        {{ detail.created_by.nickname }}
      </a-descriptions-item>
      <a-descriptions-item label="创建时间">
        {{ detail.created_at }}
      </a-descriptions-item>
      <a-descriptions-item label="更新人">
        {{ detail.updated_by.nickname }}
      </a-descriptions-item>
      <a-descriptions-item label="更新时间">
        {{ detail.updated_at }}
      </a-descriptions-item>
    </a-descriptions>
    <a-divider></a-divider>
    <div v-html="detail.content"></div>
  </a-drawer>
</template>

<script>
import { getDocument } from '@/api/document'

export default {
  name: 'DocumentDrawer',
  data () {
    return {
      visible: false,
      detail: {
        id: '',
        name: '',
        description: '',
        content: '',
        created_by: {
          nickname: ''
        },
        updated_by: {
          nickname: ''
        }
      }
    }
  },
  methods: {
    show (id) {
      getDocument(id)
        .then(res => {
          this.detail = res.data
        })
        .finally(() => {
          this.visible = true
        })
    }
  }
}
</script>
